// Generated by Framer (e39ee10)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["osCj9JGHz"];

const serializationHash = "framer-a3GgI"

const variantClassNames = {osCj9JGHz: "framer-v-ubm4fg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, DEd0kt8Nu: image ?? props.DEd0kt8Nu ?? {src: "https://framerusercontent.com/images/6l3cxbsSKqGZg8Gs9mbzGltDSpU.jpg?scale-down-to=512", srcSet: "https://framerusercontent.com/images/6l3cxbsSKqGZg8Gs9mbzGltDSpU.jpg?scale-down-to=1024 682w,https://framerusercontent.com/images/6l3cxbsSKqGZg8Gs9mbzGltDSpU.jpg?scale-down-to=2048 1365w,https://framerusercontent.com/images/6l3cxbsSKqGZg8Gs9mbzGltDSpU.jpg 1920w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DEd0kt8Nu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "osCj9JGHz", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ubm4fg", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"osCj9JGHz"} ref={ref ?? ref1} style={{backgroundColor: "rgb(247, 253, 247)", borderBottomLeftRadius: 1200, borderBottomRightRadius: 1200, borderTopLeftRadius: 1200, borderTopRightRadius: 1200, ...style}}><Image background={{alt: "", fit: "fill", sizes: `calc(${componentViewport?.width || "100vw"} - 20px)`, ...toResponsiveImage(DEd0kt8Nu), ...{ positionX: "center", positionY: "center" }}} className={"framer-1mo12dz"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"povOJKfY6"} transformTemplate={transformTemplate1}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-a3GgI.framer-tidkz8, .framer-a3GgI .framer-tidkz8 { display: block; }", ".framer-a3GgI.framer-ubm4fg { height: 72px; overflow: hidden; position: relative; width: 72px; will-change: var(--framer-will-change-override, transform); }", ".framer-a3GgI .framer-1mo12dz { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 72px); left: 50%; position: absolute; top: 50%; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 72
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DEd0kt8Nu":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerScC7qnWuy: React.ComponentType<Props> = withCSS(Component, css, "framer-a3GgI") as typeof Component;
export default FramerScC7qnWuy;

FramerScC7qnWuy.displayName = "Avatar";

FramerScC7qnWuy.defaultProps = {height: 72, width: 72};

addPropertyControls(FramerScC7qnWuy, {DEd0kt8Nu: {__defaultAssetReference: "data:framer/asset-reference,6l3cxbsSKqGZg8Gs9mbzGltDSpU.jpg?originalFilename=thisisengineering-raeng-33iHByNG4Vc-unsplash+1.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerScC7qnWuy, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})